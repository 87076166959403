define('garage/driver/driver/route', ['exports', 'garage/helpers/api-error-handler'], function (exports, _apiErrorHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var NEW_PREFIX = 'new';

    exports.default = Ember.Route.extend({

        modalMessage: Ember.inject.service(),
        i18n: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        viewData: Ember.Object.create({
            tabs: [{ name: 'tab.Info', link: 'driver.driver.index' }],
            driver: null,
            showSaveButtons: true
        }),

        model: function model(_ref) {
            var _this = this;

            var id = _ref.id;


            if (id.startsWith(NEW_PREFIX)) {
                // new unsaved
                var allDrivers = this.get('store').peekAll('driver');
                var newItem = allDrivers.find(function (v) {
                    return v.get('id') === id;
                });
                if (!newItem) {
                    // Handle invalid ID.
                    this.transitionTo('driver.index');
                }
                this.set('viewData.driver', newItem);
                this.modelFor('driver').set('selected', newItem);
                return this.get('viewData');
            } else {
                // existing saved record
                return this.get('store').findRecord('driver', id).then(function (v) {
                    _this.set('viewData.driver', v);
                    _this.modelFor('driver').set('selected', v);
                    return _this.get('viewData');
                }).catch(function () {
                    // Handle invalid ID.
                    _this.transitionTo('driver.index');
                });
            }
        },


        actions: {
            didTransition: function didTransition() {
                // solution to manage showing save buttons
                // Showing save buttons by default when current or sub route changes. Enables shift sub-route to hide buttons by sending event.
                // If sub route would override didTransition it should either manage button showing by events or
                // bubble this didTransition by returning true.
                this.set('viewData.showSaveButtons', true);
            },
            hideSaveButtons: function hideSaveButtons() {
                this.set('viewData.showSaveButtons', false);
            },
            save: function save() {
                var _this2 = this;

                var onlyOperator = this.get('currentUser').isPlainOperator();
                var driver = this.get('viewData.driver');

                var pincode = driver.get('pinCode');
                if (typeof pincode === 'string' && pincode.trim().length === 0) {
                    driver.set('pinCode', null);
                }

                // operator user needs to set driver's operator
                if (onlyOperator && !driver.get('operator')) {
                    this.get('modalMessage').showDialog(this.get('i18n').t('message.text.driver.save.failure'), this.get('i18n').t('message.text.driver.save.operatorMandatory'));
                    return;
                }

                this.get('modalMessage').show(this.get('i18n').t('message.title.saving'), this.get('i18n').t('message.text.wait'));

                var originalId = driver.get('id');
                if (originalId.startsWith(NEW_PREFIX)) {
                    driver.set('id', null);
                }
                driver.save().then(function (result) {
                    _this2.get('modalMessage').remove();
                    if (originalId.startsWith(NEW_PREFIX)) {
                        // change route to match created id in url
                        _this2.transitionTo('driver.driver.index', result.get('id'));
                    }
                    _this2.send('driverSaved', result.get('id'));
                }, function (err) {
                    // failed
                    if (originalId.startsWith(NEW_PREFIX)) {
                        driver.set('id', originalId);
                    }
                    _this2.get('modalMessage').remove();
                    var title = _this2.get('i18n').t('message.title.failure');
                    var text = _this2.get('i18n').t('message.text.driver.save.failure');
                    text = (0, _apiErrorHandler.formatApiError)(err, _this2.get('i18n'), 'driver');
                    _this2.get('modalMessage').showDialog(title, text);
                });
            },
            revert: function revert() {
                var _this3 = this;

                var title = this.get('i18n').t('dialog.revert.title');
                var text = this.get('i18n').t('dialog.revert.text');
                this.get('modalMessage').confirm(title, text, function () {
                    _this3.get('viewData.driver').rollbackAttributes();
                });
            }
        }
    });
});