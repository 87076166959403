define('garage/driver/route', ['exports', 'garage/mixins/restricted-route-mixin', 'garage/helpers/permission-utils'], function (exports, _restrictedRouteMixin, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var NEW_PREFIX = 'new';

    exports.default = Ember.Route.extend(_restrictedRouteMixin.default, {
        i18n: Ember.inject.service(),
        modalMessage: Ember.inject.service(),

        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        restrictions: [_permissionUtils.roleNames.operator, _permissionUtils.roleNames.admin, _permissionUtils.roleNames.super],

        viewData: Ember.Object.create({
            drivers: [],
            selected: null,
            hasDirty: false,
            savingAll: false,
            search: ''
        }),

        model: function model() {
            var _this = this;

            return this.get('store').findAll('driver').then(function () {
                return _this.loadDriversToViewData().then(function () {
                    return _this.get('viewData');
                });
            });
        },
        loadDriversToViewData: function loadDriversToViewData() {
            var _this2 = this;

            return Promise.resolve(this.get('store').peekAll('driver')).then(function (result) {
                var drivers = result.sortBy('name');
                _this2.set('viewData.drivers', drivers);
                _this2.set('viewData.hasDirty', drivers.any(function (i) {
                    return i.get('hasDirtyAttributes');
                }));

                return drivers;
            });
        },
        draftDriver: function draftDriver() {
            var driver = this.get('store').createRecord('driver');
            driver.set('id', this.getNextNewId());
            driver.set('name', 'New Driver');
            driver.set('status', 'active');

            return driver;
        },
        addDriver: function addDriver() {
            var driver = this.get('store').createRecord('driver');
            driver.set('id', this.getNextNewId());
            driver.set('name', 'New Driver');

            return driver;
        },
        saveAll: function saveAll() {
            var _this3 = this;

            var unsavedDrivers = this.get('store').peekAll('driver').filterBy('hasDirtyAttributes', true);

            var success = [];
            var failed = [];
            var chain = Promise.resolve();
            this.set('viewData.savingAll', true);

            var _loop = function _loop(i) {
                var driver = unsavedDrivers[i];
                if (driver.get('id') && driver.get('id').startsWith(NEW_PREFIX)) {
                    driver.set('id', null);
                }
                chain = chain.then(function () {
                    return driver.save().then(function () {
                        success.push(driver.get('id'));
                    }).catch(function (e) {
                        failed.push('\n\r' + driver.get('id') + ' (' + e.errors[0].title + ')');
                    }).catch(function (e) {
                        failed.push('\n\r' + driver.get('id') + '(' + e.message + ')');
                    });
                });
            };

            for (var i = 0; i < unsavedDrivers.length; i++) {
                _loop(i);
            }
            chain.then(function () {
                _this3.set('viewData.savingAll', false);
                var title = _this3.get('i18n').t('dialog.saved.title');
                var text = _this3.get('i18n').t('dialog.saved.text.driver', {
                    success: success.join(', ') || '-',
                    totalSuccess: success.length,
                    failed: failed.join(', ') || '-'
                });
                _this3.get('modalMessage').showDialog(title, text);
                _this3.refresh();
            });
        },
        getNextNewId: function getNextNewId() {
            // find highest new number
            var allDrivers = this.get('store').peekAll('driver');

            var highestId = allDrivers.reduce(function (prev, v) {
                var currId = v.get('id');
                if (currId && currId.startsWith(NEW_PREFIX)) {
                    var components = currId.split('-');
                    var number = parseInt(components[1], 10);
                    prev = isNaN(number) ? prev : number;
                }
                return prev;
            }, 0);

            return NEW_PREFIX + '-' + ++highestId;
        },


        actions: {
            search: function search() {
                var filter = this.get('viewData.search').toLowerCase();
                this.get('viewData.drivers').forEach(function (driver) {
                    driver.set('filtered', filter && driver.get('name').toLowerCase().indexOf(filter) < 0);
                });
            },
            selectDriver: function selectDriver(driver) {
                // search current route name to maintain selected tab with the new selected driver
                var route = Ember.getOwner(this).lookup('controller:application').currentRouteName;
                this.set('viewData.selected', driver);
                if (route === 'driver.index') {
                    route = 'driver.driver.index';
                }
                this.transitionTo(route, driver.id);
            },
            deleteDriver: function deleteDriver(driver) {
                var _this4 = this;

                var title = this.get('i18n').t('dialog.delete.title', { name: driver.get('name') });
                var text = this.get('i18n').t('dialog.delete.text');
                this.get('modalMessage').confirm(title, text, function () {
                    driver.deleteRecord();
                    driver.save().then(function () {
                        _this4.transitionTo('driver.index');
                        title = _this4.get('i18n').t('message.title.success');
                        text = _this4.get('i18n').t('message.text.driver.delete.success');
                        _this4.get('modalMessage').showDialog(title, text);
                        _this4.refresh();
                    }, function () {
                        title = _this4.get('i18n').t('message.title.failure');
                        text = _this4.get('i18n').t('message.text.driver.delete.failure');
                        _this4.get('modalMessage').showDialog(title, text);
                    });
                });
            },
            copyDriver: function copyDriver(driver) {
                var copy = driver.copy();
                copy.set('id', this.getNextNewId());
                this.transitionTo('driver.driver.index', copy.get('id'));
            },
            newDriver: function newDriver() {
                var driver = this.draftDriver();
                this.transitionTo('driver.driver.index', driver.get('id'));
            },
            addDriver: function addDriver(json) {
                var store = this.get('store');
                var driver = store.hasRecordForId('driver', json.id) ? store.peekRecord('driver', json.id) : this.draftDriver();
                driver.setProperties(json);
                this.loadDriversToViewData();
            },
            saveAll: function saveAll() {
                this.saveAll();
            },
            clearUnsaved: function clearUnsaved() {
                // En jumalauta kemsinyt miten emberissä saa hienosti rollbäckattyä dirty recordit
                // unloadAll toimi jossain tilanteissa mutta joskus tuli poikkeuksia mitä ei tietenkään
                // mitenkään pysty helposti debuggaan. Ember data on susi tuote.
                window.location.reload(true);
            },


            // receives id as parameter
            driverSaved: function driverSaved() {
                this.refresh();
            }
        }
    });
});